<template>
    <div class="footer-bottom">
        <div class="copyright_desc" v-html="copyRight.copyright_desc"></div>
        <p>
            <a v-if="copyRight.address" class="footer-link">公司地址：{{ copyRight.address }}</a>
        </p>
        <p>
            <router-link target="_blank" class="footer-link" :to="{ path: '/licence' }" v-if="copyRight.business_license">营业执照</router-link>
            &ensp; &ensp;
            <a v-if="copyRight.report" class="footer-link" :href="copyRight.report" target="_blank">违法不良信息举报平台</a>
            &ensp; &ensp;
            <a v-if="copyRight.market_supervision_url" class="footer-link" :href="copyRight.market_supervision_url" target="_blank">市场监督管理局</a>
            &ensp; &ensp;
            <a v-if="copyRight.icp" class="footer-link" href="https://beian.miit.gov.cn" target="_blank">备案号：{{ copyRight.icp }}</a>
        </p>
        <p>
            <a v-if="copyRight.copyright_title" class="footer-link" :href="copyRight.copyright_link" target="_blank">版本所有：{{ copyRight.copyright_title }}</a>
        </p>
        <p>
            <a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank">
                <img src="@/assets/images/gov_record.png" alt="公安备案" />
                <span>{{ copyRight.gov_record }}</span>
            </a>
        </p>
        <div class="qrcode">
            <img :src="$img(qrcode)" style="width:120px"  v-if="qrcode"/>
            <p v-if="qrcode">微信小程序</p>
        </div>
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        created() {
            this.$store.dispatch("site/copyRight")
        },
        mounted() {},
        watch: {},
        methods: {
            // 打开许可证页面
            toLicence() {
                this.$router.push("/licence")
            }
        },
        computed: {
            ...mapGetters(["copyRight", "siteInfo", "wapQrcode"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.weapp.img
            }
        }
    }
</script>

<style scoped lang="scss">
    .footer-bottom {
        width: 1210px;
        min-height: 85px;
        margin: 0 auto;
        padding: 15px 0 60px 0;
        position: relative;

        .qrcode {
            width: 150px;
            height: 150px;
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .copyright_desc {
            text-align: center;
        }
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link,
            .el-link.el-link--default {
                color: #9d9d9d;
            }
            .footer-link:hover,
            .el-link.el-link--default:hover {
                color: $base-color;
            }
        }
        .site-info {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
