<template>
    <div class="header-in">
        <el-row>
            <el-col :span="12">
                <a href="javascript:;" @click="goHome" class="logo-wrap">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                    <!-- 无图片时暂时不显示 <img v-else src="@/assets/images/logo.png" /> -->
                    <div class="region" @click="openCity" v-if="floatLayer.whole_country == '1'">
                        <span class="posi">
                            <i class="n el-icon-location-information" style="color:#ecb01d"></i>全国<i class="n el-icon-arrow-right"></i>
                        </span>
                    </div>
                </a>
            </el-col>
            <el-col :span="8">
                <div class="in-sousuo">
                    <div class="sousuo-box">
                        <el-dropdown @command="handleCommand" trigger="click">
                            <span class="el-dropdown-link">
                                {{ searchTypeText }}
                                <i class="el-icon-arrow-down"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="goods">商品</el-dropdown-item>
                                <el-dropdown-item command="shop" v-if="shopIsShow">店铺</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <input type="text" v-model.trim="keyword" @keyup.enter="search" maxlength="50" :placeholder="defaultSearchWords" />
                        <el-button type="primary" size="small" @click="search"><i class="el-icon-search" style="font-size:26px;line-height: 36px"></i></el-button>
                    </div>
                    <!--<div class="hot-search-words" v-if="hotSearchWords.length">
                        <span>热门搜索：</span>
                        <ul>
                            <li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
                                {{ item }}
                            </li>
                        </ul>
                    </div>-->
                </div>
            </el-col>
            <el-col :span="4">
                <div class="cart-wrap">
                    <router-link class="cart" :to="'/cart?source_type=' + source_type">
                        <span>我的购物车</span>
                        <el-badge v-if="cartCount" :value="cartCount" type="primary"><i class="iconfont icongouwuche"></i></el-badge>
                        <i v-else class="iconfont icongouwuche"></i>
                    </router-link>

                    <div class="list">
                        <template v-if="cartList.length">
                            <h4>最新加入的商品</h4>
                            <div class="overflow-wrap">
                                <ul :class="{ overflow: cartList.length > 5 }">
                                    <li class="item" v-for="(item, index) in cartList" :key="index">
                                        <div class="img-wrap"><img :src="$img(item.sku_image, { size: 'small' })" @error="imageError(index)" :alt="item.sku_name" /></div>
                                        <div class="goods-name" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
                                        {{ item.sku_name }}
                                        </div>
                                        <div class="operation">
                                            <p>￥{{ item.discount_price }}x{{ item.num }}</p>
                                            <span @click="deleteCart(index)">删除</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="total">
                                <span>
                                    共
                                    <strong>{{ cartList.length }}</strong>
                                    种商品，总金额
                                    <strong>{{ cartTotalPrice }}</strong>
                                    元
                                </span>

                                <el-button type="primary" size="mini" @click="$router.push('/cart?source_type=' + source_type)">去购物车</el-button>
                            </div>
                        </template>
                        <div class="empty" v-else>
                            <i class="iconfont icongouwuche"></i>
                            <span>您的购物车是空的，赶快去逛逛，挑选商品吧！</span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog title="请选择"
                   :visible.sync="showWebsiteCity"
                   width="800px"
                   :before-close="handleCloseCity">
            <div class="websiteCity-box" v-loading="loadingCity">
                <ul>
                    <li class="items" :class="'' == activeCity ? 'active' : ''" @click="handleSelect()">全国</li>
                    <li class="items" :class="item.site_id == activeCity ? 'active' : ''" v-for="(item,index) in websiteCityData" :key="index" @click="handleSelectWebsiteCity(item.site_id,item.website_type)">
                        {{ item.title }}
                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { apiHotSearchWords, apiDefaultSearchWords,floatLayer } from "@/api/pc"
    import {websiteList} from "@/api/goods/goods";
    import { cartList as apiCartList } from "@/api/goods/cart"
    import { getToken } from "@/utils/auth"
    import  Cookies from "js-cookie"
    export default {
        props: {},
        data() {
            return {
                shopIsShow: true, // 是否显示店铺搜索
                searchType: "goods",
                searchTypeText: "商品",
                keyword: "",
                hotSearchWords: [],
                defaultSearchWords: "",
                cartList: [],
                website_id: 0,
                source_type: 0,
                cartTotalPrice: 0,
                showWebsiteCity: false,
                loadingCity: true,
                activeCity:'',
                websiteCityData:[],
                floatLayer:{
                    whole_country:0,
                }
            }
        },
        components: {},
        computed: {
            ...mapGetters(["cartCount", "siteInfo", "defaultGoodsImage", "member"])
        },
        created() {
            this.keyword = this.$route.query.keyword || ""
            if (this.$route.name == "street") this.searchType = "shop"
            else this.searchType = "goods"
            this.$store.dispatch("site/siteInfo")

            this.website_id = parseInt(this.$route.query.website_id) | 0
            this.source_type = parseInt(this.$route.query.source_type) | 0
            // this.getHotSearchWords()
            this.getDefaultSearchWords()
            if (getToken() !== undefined) this.getCartList()
            this.getWebSiteInfo()
            this.getFloatLayer()//显示全国
        },
        watch: {
            searchType() {
                this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
            },
            $route(curr) {
                if (this.keyword !== curr.query.keyword) {
                    this.keyword = curr.query.keyword
                }
                if (this.$route.name == "street") this.searchType = "shop"
                else this.searchType = "goods"
            },
            cartCount() {
                if (getToken() !== undefined) this.getCartList()
            },
            member() {
                if (!this.member) {
                    this.$store.commit("cart/SET_CART_COUNT", 0)
                    this.cartList = []
                    this.cartTotalPrice = 0
                }
            }
        },
        methods: {
            getFloatLayer () {
                floatLayer()
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.floatLayer = res.data
                        }
                    })
                    .catch(err => err)
            },
            // 获取分站信息
            getWebSiteInfo() {
                let website_id = Cookies.get("website_id")
                let website_type = Cookies.get("website_type")
                // 当前分站是除城市分站以外的时
                if (website_id && parseInt(website_type) > 1) {
                    this.shopIsShow = false
                } else {
                    this.shopIsShow = true
                }
            },
            goHome() {
                // this.$router.push("/")

                let currPath = Cookies.get("indexDetail_web_domain")
                // 需要校验的url路径 需要强制跳转的判断页面
                let isChangeingUrlLists = ["http://" + currPath + "/web/index", "https://" + currPath + "/web/index", "http://" + currPath + "/web", "https://" + currPath + "/web"]
                let currHref = window.location.href
                // 当前路径是否包含在校验路径中？
                let ischangeBoolean = false
                isChangeingUrlLists.forEach(element => {
                    if (currHref === element) {
                        ischangeBoolean = true
                    }
                })
                // 兼容从专馆跳转的判断
                if (!ischangeBoolean) {
                    this.$router.push({
                        name: "/",
                        params: {
                            back_index: "back_index"
                        }
                    })
                } else {
                    this.$router.push("/")
                }
            },
            handleCommand(command) {
                this.searchType = command
            },
            search() {
                if (this.searchType == "goods") this.$router.push({ path: "/list", query: { keyword: this.keyword } })
                else this.$router.push({ path: "/street", query: { keyword: this.keyword } })
            },
            getHotSearchWords() {
                apiHotSearchWords({}).then(res => {
                    if (res.code == 0 && res.data.words) {
                        this.hotSearchWords = res.data.words.split(",")
                    }
                })
            },
            getDefaultSearchWords() {
                apiDefaultSearchWords({}).then(res => {
                    if (res.code == 0 && res.data.words) {
                        this.defaultSearchWords = res.data.words
                        this.keyword = res.data.words
                    }
                })
            },
            // 获取购物车数据
            getCartList() {
                apiCartList({})
                    .then(res => {
                        if (res.code >= 0 && res.data.length) {
                            this.cartTotalPrice = 0
                            this.cartList = res.data
                            this.cartList.forEach(item => {
                                this.cartTotalPrice += item.discount_price * item.num
                            })
                            this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
                        }
                    })
                    .catch(res => {})
            },
            imageError(index) {
                this.cartList[index].sku_image = this.defaultGoodsImage
            },
            // 删除单个购物车商品
            deleteCart(index) {
                this.$store
                    .dispatch("cart/delete_cart", {
                        cart_id: this.cartList[index].cart_id.toString()
                    })
                    .then(res => {
                        if (res.code >= 0) {
                            this.cartList.splice(index, 1)
                            this.$forceUpdate()
                        }
                    })
                    .catch(err => {})
            },
            // 点击打开弹窗
            openCity() {
                this.showWebsiteCity = !this.showWebsiteCity
                this.getWebsiteList()
            },
            //获取城市分站集合
            getWebsiteList() {
                websiteList({ type: 1 })
                    .then(res => {
                        if (res.code == 0) {
                            this.websiteCityData = res.data
                            this.loadingCity = false
                        }
                    })
                    .catch(err => {
                        this.loadingCity = false;
                        this.$message.error(err.message);
                    });
            },
            handleSelect(){
                this.showWebsiteCity = false
                this.activeCity = ''
                this.$router.push('/')
                this.reload();
            },
            handleSelectWebsiteCity(id,type){
                this.showWebsiteCity = false
                this.$router.push({ path: "/citylist", query: { website_id: id, website_type: type} })
                this.reload();
            },
            // 关闭弹窗事件
            handleCloseCity(done) {
                this.showWebsiteCity = false
                done()
            },
        }
    }
</script>

<style scoped lang="scss">

    .websiteCity-box{
        position: relative;
        margin:0;
        padding: 0;
        li {
            display: inline-block;
            padding: 0 40px;
            cursor: pointer;
            font-size: 20px;
            line-height: 40px;
            min-width: 70px;
            color:#999;
        }
        li:hover,li.active {
            color: $base-color;
        }
    }
    .header-in {
        width: $width;
        height: 110px;
        margin: 0 auto;
        .logo-wrap {
            width: 360px;
            height: auto;
            line-height: 100px;
            display: block;
            position: relative;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            .region {
                position: absolute;
                right:-115px;
                top:42px;
                cursor: pointer;
                .posi {
                    text-align: center;
                    position: relative;
                    width: 78px;
                    height: 26px;
                    display: block;
                    line-height: 26px;
                    box-sizing: border-box;
                    color: #535353;
                    font-size: 14px;
                    border: 1px solid #E1E1E1;
                    border-radius: 2px;
                }
                i{
                    margin: 0px 2px;
                }
            }
        }
        .in-sousuo {
            width: 380px;
            margin-top: 35px;
            .sousuo-box {
                width: 100%;
                height: 40px;
                border: 2px solid $base-color;
                box-sizing: border-box;
                border-radius: 5px;
                .el-dropdown {
                    padding: 0 6px;
                    height: 38px;
                    cursor: pointer;
                    &::after {
                        content: "";
                        border-left: 1px solid #cfcfcf;
                        margin-left: 5px;
                    }
                }
                input {
                    width: 240px;
                    height: 36px;
                    background: none;
                    outline: none;
                    border: none;
                    margin: 0;
                    padding: 0;
                    color: #606266;
                }
                button {
                    border-radius: 0;
                    float: right;
                    height: 38px;
                    width: 68px;
                    line-height: 38px;
                    margin: -1px;
                    padding: 0;
                }
            }
            .hot-search-words {
                width: 100%;
                height: 20px;
                margin-top: 5px;
                font-size: 13px;
                color: #c0c4cc;
                span {
                    float: left;
                }
                ul {
                    overflow: hidden;
                    margin: 0;
                    height: 21px;
                    padding: 0;
                    color: #c0c4cc;
                    li {
                        cursor: pointer;
                        list-style: none;
                        float: left;
                        margin-right: 10px;
                        &:hover {
                            color: $base-color;
                        }
                    }
                }
            }
        }
        .cart-wrap {
            position: relative;
            width: 127px;
            margin-top: 35px;
            .cart {
                text-align: center;
                margin: 0;
                width: 125px;
                height: 38px;
                padding: 0;
                border: 1px solid #c1bcbc;
                border-radius: 3px;
                color: $base-color;
                font-size: $ns-font-size-sm;
                display: block;
                position: absolute;
                right: 0;
                z-index: 101;
                span {
                    cursor: pointer;
                    line-height: 38px;
                    margin-right: 10px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #d81e01;
                }
            }
            &:hover {
                .cart {
                    border-bottom: 1px solid #ffffff;
                }
                .list {
                    display: block;
                }
            }
            .list {
                position: absolute;
                top: 39px;
                left: 0px;
                width: 320px;
                background: #fff;
                border: 1px solid #c1bcbc;
                display: none;
                border-radius: 3px;
                z-index: 100;
                h4 {
                    height: 25px;
                    padding: 6px 8px;
                    line-height: 25px;
                }
                .overflow-wrap {
                    width: 315px;
                    overflow: hidden;
                    ul {
                        max-height: 335px;
                        &.overflow {
                            overflow: auto;
                            width: 354px;
                        }
                        li {
                            background-color: #fff;
                            display: block;
                            font-size: 12px;
                            padding: 8px 10px;
                            position: relative;
                            border-bottom: 1px solid #dfdfdf;
                            overflow: hidden;
                            .img-wrap {
                                width: 50px;
                                height: 50px;
                                margin-right: 5px;
                                overflow: hidden;
                                float: left;
                                text-align: center;
                                line-height: 50px;
                            }
                            .goods-name {
                                float: left;
                                width: 140px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                margin-right: 10px;
                                cursor: pointer;
                            }
                            .operation {
                                float: right;
                                text-align: right;
                                width: 90px;
                                p {
                                    color: $base-color;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                span {
                                    cursor: pointer;
                                    &:hover {
                                        color: $base-color;
                                    }
                                }
                            }
                            &:last-child {
                                border-bottom: 0;
                            }
                        }
                    }
                }
                .total {
                    background-color: #fff;
                    display: block;
                    font-size: 12px;
                    padding: 8px 10px;
                    position: relative;
                    border-bottom: 1px solid #dfdfdf;
                    overflow: hidden;
                    background-color: #f0f0f0;
                    border-bottom: 0;
                    left: 0;
                    span {
                        margin-top: 5px;
                        display: inline-block;
                        width: 70%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        strong {
                            margin: 0 2px;
                            color: $base-color;
                            font-size: $ns-font-size-base;
                        }
                    }
                    button {
                        float: right;
                    }
                }

                .empty {
                    width: auto;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    color: #999;
                    i {
                        font-size: 28px;
                    }
                    span {
                        display: inline-block;
                        font-size: 12px;
                        padding-right: 20px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
</style>
