<template>
	<div class="floor-style-3">
		<div class="item-wrap">
			<div class="head-wrap">
				<div class="title-name" v-if="data.value.title.value.text">
					<span :style="{ backgroundColor: data.value.title.value.color }"></span>
					<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
				</div>
				<div class="category-wrap">
					<li v-for="(item, index) in data.value.categoryList.value.list" :key="index">
						<router-link target="_blank" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{ item.category_name }}</router-link>
					</li>
				</div>
			</div>
			<div class="body-wrap">
				<div class="left-img-wrap">
					<img v-if="data.value.leftImg.value.url" :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
				</div>
				<ul class="right-goods-wrap">
					<li v-for="(item, index) in data.value.rightGoodsList.value.list" :key="index" @click="goSku(item.sku_id)">
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageErrorRight(index)" /></div>
						<h4>
							<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
							<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
							<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
							<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
							<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
							{{ item.goods_name }}</h4>
						<p class="ns-text-color">{{ item.introduction }}</p>
						<span class="tag taggreen" v-if="item.recommend_way ==1">新品</span>
						<span class="tag tagblue" v-if="item.recommend_way ==2">精品</span>
						<span class="tag" v-if="item.recommend_way ==3">推荐</span>
						<p class="price">
							<span class="num">￥{{ item.discount_price }}</span>
						</p>
					</li>
				</ul>
				<ul class="bottom-goods-wrap">
					<li v-for="(item, index) in data.value.bottomGoodsList.value.list" :key="index" @click="goSku(item.sku_id)">
						<div class="info-wrap">
							<h4>{{ item.goods_name }}</h4>
							<p class="ns-text-color">{{ item.introduction }}</p>
						</div>
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image, { size: 'small' })" @error="imageErrorBottom(index)" /></div>
					</li>
				</ul>

				<ul class="brand-wrap">
					<li v-for="(item, index) in data.value.brandList.value.list" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
						<img alt="品牌图片" :src="$img(item.image_url)" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-3',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageErrorRight(index) {
			this.data.value.rightGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		},
		imageErrorBottom(index) {
			this.data.value.bottomGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-3 {
	overflow: hidden;

	.item-wrap .head-wrap {
		height: 50px;
		line-height: 50px;
		.title-name {
			display: inline-block;
			span {
				float: left;
				width: 5px;
				height: 21px;
				margin-top: 15px;
			}
			h2 {
				float: left;
				margin-left: 10px;
				font-weight: bold;
				font-size: 20px;
			}
		}
		.category-wrap {
			float: right;
			display: flex;
			li {
				margin-right: 10px;
			}
		}
	}
	.body-wrap {
		.left-img-wrap {
			width: 190px;
			float: left;
			cursor: pointer;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		.right-goods-wrap {
			margin-left: 190px;
			text-align: center;
			overflow: hidden;
			background: #ffff;
			li {
				float: left;
				width: 25%;
				margin-bottom: 20px;
				cursor: pointer;
				transition: all 0.2s linear;
				position: relative;
				h4 {
					font-size: 15px;
					margin: 10px 20px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-align: left;
					text-overflow: ellipsis;
					font-weight: normal;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 4px 20px;
					height: 20px;
				}
				.tag {
					display: inline-block;
					position: absolute;
					top: 18px;
					right: 24px;
					z-index: 1;
					font-size: 12px;
					width: 36px;
					background-color: #e84440;
					color: #fff;
					text-align: center;
					border-radius: 2px;
					letter-spacing: 2px;
				}
				.tag.taggreen {
					background-color: #07b85a;
				}
				.tag.tagblue {
					background-color: #0077FF;
				}

				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
			}

			.img-wrap {
				width: 90%;
				height: 250px;
				display: inline-block;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			.price{ font-size: 16px; color: red; font-weight: bold; text-align: left;}
		}
	}
	.bottom-goods-wrap {
		overflow: hidden;
		display: flex;
		li {
			flex: 1;
			background: #fff;
			border-width: 0 0 1px 1px;
			border-color: #f9f9f9;
			border-style: solid;
			cursor: pointer;
			&:first-child {
				border-left: 0;
			}

			.info-wrap {
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				h4 {
					font-size: 14px;
					margin: 0 10px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 90px;
					font-weight: normal;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 0 20px;
					width: 70px;
				}
			}
			.img-wrap {
				width: 70px;
				height: 70px;
				line-height: 70px;
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				padding: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.brand-wrap {
		display: flex;
		background: #fff;
		li {
			flex: 1;
			height: 50px;
			cursor: pointer;
			line-height: 50px;
			text-align: center;
			background: #fff;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
</style>
