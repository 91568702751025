import BigdataLayout from "../../layout/basebigdata"

const promotionRoutes = [
	{
		path: "/bigdata/index",
		name: "bigdata",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/index")
	},
	{
    // 扶贫目录查看页
		path: "/bigdata/fupin_catalog",
		name: "fupin_catalog",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/fupin_catalog")
	},
	{
    // 全省各市州采购排行
		path: "/bigdata/city_purchase",
		name: "city_purchase",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/city_purchase")
	},
	{
    // 全省各市州销售排行
		path: "/bigdata/city_sell",
		name: "city_sell",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/city_sell")
	},
	{
    // 全省各单位采购销售排行
		path: "/bigdata/budget_purchase",
		name: "budget_purchase",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/budget_purchase")
	},
	{
    // 全省各单位采购排行
		path: "/bigdata/budget_recharge",
		name: "budget_recharge",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/budget_recharge")
	},
	{
    // 全省各单位充值排行
		path: "/bigdata/education_combined",
		name: "education_combined",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/education_combined")
	},
	{
    // 全省教育联采排行
		path: "/bigdata/education_combined",
		name: "education_combined",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/education_combined")
	},
	{
    // 全省教育联采排行
		path: "/bigdata/shop_lst",
		name: "shop_lst",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/shop_lst")
	},
	{
    // 全省教育联采排行
		path: "/bigdata/category_lst",
		name: "category_lst",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/category_lst")
	},
	{
    // 全省教育联采排行
		path: "/bigdata/goods_lst",
		name: "goods_lst",
		meta: {
			module: "bigdata",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/bigdata/goods_lst")
	},
]

// 其他模块
export default {
	path: "/",
	component: BigdataLayout,
	redirect: "/index",
	name: "Index",
	children: [
		...promotionRoutes,
	]
}
