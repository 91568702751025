<!-- 城市分站  全国-->
<template>
    <div class="local-branch-list">
        <div class="floor-head" >
            <h2>
                <img src="@/assets/images/nationwide/title_l.png"/>
                地方分馆
                <img src="@/assets/images/nationwide/title_r.png"/>
            </h2>
        </div>
        <div class="module-list-body">
            <div class="card-carousel-title">
                <div class="card-carousel-wrapper website-name">
                    <div class="card-carousel--nav__left" @click="moveCarouselWebsiteName(-1)" :disabled="atHeadOfLisWebsiteNamet"></div>
                    <div class="card-carousel">
                        <div class="card-carousel--overflow-container">
                            <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffsetWebsiteName + 'px' + ')'}">
                                <div class="card-carousel--card" v-for="item in websiteData" :key="item.site_id" @click="handleClickWebsite(item.site_id,item.title,item.logo,item.website_type)">
                                    <div class="website-name-wrap">
                                        <img v-if="item.logo" :src="item.logo"/>
                                        <img v-else src="@/assets/images/nationwide/csz.png"/>
                                        <div class="card-carousel--card--footer">
                                            <p>{{ item.title }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-carousel--nav__right" @click="moveCarouselWebsiteName(1)" :disabled="atEndOfListWebsiteName"></div>
                </div>
            </div>
            <div class="card-carousel-body">
                <div class="card-carousel-img">
                    <img v-if="checkSitelogo!=''" :src="checkSitelogo"/>
                    <img v-else src="@/assets/images/nationwide/csz.png"/>
                    <div class="title-name">
                        <p>{{checkSiteName}}</p>
                        <router-link target="_blank" :to="{ path: '/citylist', query: { website_id: site_Id, website_type: website_type} }">
                            <span>进入分馆 -></span>
                        </router-link>
                    </div>
                </div>
                <div class="card-carousel-goods" v-if="recommendGoodsData.length>0">
                    <div class="card-carousel-wrapper website-goods">
                        <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
                        <div class="card-carousel">
                            <div class="card-carousel--overflow-container">
                                <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
                                    <div class="card-carousel--card" v-for="item in recommendGoodsData"
                                         :key="item.goods_id"
                                         @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?website_id='+site_Id+'&source_type=1'})">
                                        <div class="goods-wrap">
                                            <div class="img-wrap"><img alt="商品图片" :src="$img(item.goods_image[0], { size: 'mid' })" @error="imageError(index)" /></div>
                                            <h3>
<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.goods_name }}</h3>
                                            <p class="price">
                                                <span class="num">￥{{ item.price }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
                    </div>
                </div>
                <div class="card-carousel-goods" v-else>
                    <p style="line-height: 315px;text-align: center">暂无推荐商品！</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {recommendGoodsList, websiteList} from "@/api/goods/goods";

    export default {
        components: {},
        computed: {
            atEndOfListWebsiteName() {
                return this.currentOffsetWebsiteName <= (this.paginationFactorWebsiteName * -1) * (this.websiteData.length - this.windowSizeWebsiteName);
            },
            atHeadOfLisWebsiteNamet() {
                return this.currentOffsetWebsiteName === 0;
            },
            atEndOfList() {
                return this.currentOffset <= (this.paginationFactor * -1) * (this.recommendGoodsData.length - this.windowSize);
            },
            atHeadOfList() {
                return this.currentOffset === 0;
            }
        },
        data() {
            return {
                currentOffsetWebsiteName: 0,
                windowSizeWebsiteName: 10,
                paginationFactorWebsiteName: 115,
                currentOffset: 0,
                windowSize: 4,
                paginationFactor: 246,
                checkSiteName: '全国站',
                checkSitelogo: '',
                website_type: '',
                site_Id: '',
                websiteData:[],
                recommendGoodsData:[],
                webSiteType: 1,
                citySitCount:0,
            };
        },
        mounted() {
            this.getWebsiteList()
        },
        methods: {
            moveCarouselWebsiteName(direction) {
                if (direction === 1 && !this.atEndOfListWebsiteName) {
                    this.currentOffsetWebsiteName -= this.paginationFactorWebsiteName;
                } else if (direction === -1 && !this.atHeadOfLisWebsiteNamet) {
                    this.currentOffsetWebsiteName += this.paginationFactorWebsiteName;
                }
            },
            moveCarousel(direction) {
                if (direction === 1 && !this.atEndOfList) {
                    this.currentOffset -= this.paginationFactor;
                } else if (direction === -1 && !this.atHeadOfList) {
                    this.currentOffset += this.paginationFactor;
                }
            },
            handleClickWebsite(siteId,siteName,logo,website_type){
                console.log(siteId,siteName,logo,website_type)
                this.currentOffset =0;
                this.checkSiteName = siteName
                this.checkSitelogo = (typeof logo !='undefined' ) ? logo : ''
                this.site_Id = siteId
                this.website_type = website_type
                this.getRecommendGoodsList(siteId,1)
            },
            //获取城市分站集合
            getWebsiteList() {
                websiteList({ type: 1 })
                    .then(res => {
                        if (res.code == 0) {
                            this.websiteData = res.data
                            let site_id = (typeof res.data[0] !='undefined' ) ? res.data[0].site_id : 0
                            let website_type = (typeof res.data[0] !='undefined' ) ? res.data[0].website_type : 0
                            let website_title = (typeof res.data[0] !='undefined' ) ? res.data[0].title : 0
                            let website_logo = (typeof res.data[0] !='undefined' ) ? res.data[0].logo : ''
                            if(site_id > 0 && website_type > 0){
                                this.activeCityName = (site_id).toString()
                                this.checkSiteName = website_title
                                this.checkSitelogo = (typeof website_logo !='undefined' ) ? website_logo : ''
                                this.site_Id = site_id
                                this.website_type = website_type
                                this.getRecommendGoodsList(site_id,website_type)
                            }
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
            //获取城市分站推荐产品
            getRecommendGoodsList(site_id,website_type) {
                recommendGoodsList({ site_id: site_id, website_type: website_type })
                    .then(res => {
                        if (res.code == 0 && res.data.list.length>0) {
                            // console.log('获取城市分站推荐产品==', res.data)
                            this.recommendGoodsData = res.data.list;
                        }else{
                            this.recommendGoodsData =[]
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
        }
    }
</script>

<style lang="scss">
    /*城市分站*/
    .local-branch-list  {
        width: 1210px;
        display: block;
        margin: 0 auto;

        .card-carousel-wrapper .card-carousel--overflow-container{
            padding:0;
        }
        .card-carousel-wrapper .card-carousel .card-carousel-cards .card-carousel--card {
            box-shadow: none;
            margin: 20px 0px;
        }
        .card-carousel--nav__left, .card-carousel--nav__right {
            display: inline-block;
            width: 15px;
            height: 15px;
            padding: 0;
            box-sizing: border-box;
            border-top: 2px solid $base-color;
            border-right: 2px solid $base-color;
            cursor: pointer;
            margin: 0 10px;
            transition: transform 150ms linear;
        }
        .card-carousel--nav__left[disabled], .card-carousel--nav__right[disabled] {
            opacity: 0.2;
            border-color: black;
        }
        .card-carousel--nav__left {
            transform: rotate(-135deg);
        }
        .card-carousel--nav__left:active {
            transform: rotate(-135deg) scale(0.9);
        }
        .card-carousel--nav__right {
            transform: rotate(45deg);
        }
        .card-carousel--nav__right:active {
            transform: rotate(45deg) scale(0.9);
        }


        .card-carousel-title {
            width: 100%;
            padding: 20px 0px;
            background: #FFFFFF;
            border-radius: 8px;
            position: relative;

            .website-name-wrap{
                width: 115px;
                text-align: center;
                cursor: pointer;
                padding: 20px 0px 0px;
                img{
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    margin: 0 auto;
                }
                p{
                    margin-top: 8px;
                    font-size: 15px;
                    color:$ns-text-color-black;
                    text-align: center;
                }
            }
            .website-name-wrap:hover p{color: $base-color;margin-top: 2px}
            .website-name-wrap:hover img{width: 108px;height: 108px;margin-top: -5px}
        }
        .card-carousel-body{
            width: 100%;
            margin-top: 15px;
            display: flex;
            position: relative;
            height: 300px;
            .card-carousel-img{
                display: flex;
                width: 230px;
                margin-right: 10px;
                height: 100%;
                position: relative;
                img{width:240px;}
                .title-name{
                    width: 100%;
                    height: 88px;
                    background-color: $base-color;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    text-align: right;
                    color: #fff;
                    p{
                        padding-right: 20px;
                        font-size: 38px;
                        font-weight: bold;
                        line-height: 60px;
                    }
                    span{
                        display: block;
                        padding-right: 20px;
                        font-size: 14px;
                        line-height: 16px;
                        color: #fff;
                    }
                    span:hover{
                        color: #fff;
                    }
                }
            }
            .card-carousel-goods{
                display: flex;
                width: 970px;
                height: 100%;
                position: relative;


                .card-carousel-wrapper .card-carousel .card-carousel-cards .card-carousel--card{
                    margin: 0px 8px;
                    height: 300px;
                }
                .card-carousel--nav__left, .card-carousel--nav__right {
                    position: absolute;
                    top:135px;
                    z-index: 100001;
                }
                .card-carousel--nav__left{
                    left: 3px;
                }
                .card-carousel--nav__right{
                    right:-5px;
                }

                .card-carousel-wrapper .card-carousel .card-carousel-cards .card-carousel--card .goods-wrap{
                    width: 230px;
                    padding: 15px 0px;
                    .img-wrap {
                        width: 170px;
                        height: 170px;
                        margin: 10px auto 0px;
                        text-align: center;
                        line-height: 170px;
                        /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);*/
                        /*border-radius: 16px;*/
                        overflow: hidden;
                        position:relative;
                        img {
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                        }
                    }
                    h3 {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        white-space: inherit;
                        -webkit-line-clamp: 2;
                        height: 44px;
                        -webkit-box-orient: vertical;
                        margin: 10px 12px 3px;
                    }
                    .price {
                        margin:0 15px;
                        font-size: 20px;
                        text-align: left;
                        color: $base-color;
                        del {
                            margin-left: 10px;
                            color: #b0b0b0;
                            font-size: 16px;
                        }
                    }
                }·

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }
            }
        }
    }

    .website-name.card-carousel-wrapper,.website-goods.card-carousel-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    /*名称*/
    .website-name.card-carousel-wrapper .card-carousel{
        width: 1210px;
        height:170px;
    }

    /*推荐产品*/
    .website-goods.card-carousel-wrapper .card-carousel{
        width: 970px;
        height:300px;
    }

    /*城市分站*/
</style>