import Config from "./config.js"

export default {
    /**
     * 函数：乘法函数，用来得到精确的乘法结果
     * */
    Mul(arg1, arg2) {
        var r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
        m = (r1.split(".")[1] ? r1.split(".")[1].length : 0) + (r2.split(".")[1] ? r2.split(".")[1].length : 0);
        resultVal = Number(r1.replace(".", "")) * Number(r2.replace(".", "")) / Math.pow(10, m);
        return typeof d !== "number" ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
    },
    /**
    * 函数，加法函数，用来得到精确的加法结果
    * */
    Add (arg1, arg2) {
        arg1 = arg1.toString(), arg2 = arg2.toString();
        var arg1Arr = arg1.split("."), arg2Arr = arg2.split("."), d1 = arg1Arr.length == 2 ? arg1Arr[1] : "", d2 = arg2Arr.length == 2 ? arg2Arr[1] : "";
        var maxLen = Math.max(d1.length, d2.length);
        var m = Math.pow(10, maxLen);
        var result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen));
        var d = arguments[2];
        return typeof d === "number" ? Number((result).toFixed(d)) : result;
    },
    /**
     * 图片路径转换options.extract
     * @param {String} img_path 图片地址
     * @param {Object} params 参数，针对商品、相册里面的图片区分大中小，size: big、mid、small
     */
    img(img_path, params) {
        if(img_path && img_path.indexOf("fupin832") != -1){
            return img_path;//如果是832图片不需要拼接
        }else{
            var path = ""
            if (img_path != undefined && img_path != "") {
                if (params && img_path != vue.$store.getters.defaultGoodsImage) {
                    // 过滤默认图
                    let arr = img_path.split(".")
                    let suffix = arr[arr.length - 1]
                    arr.pop()
                    arr[arr.length - 1] = arr[arr.length - 1] + "_" + params.size
                    arr.push(suffix)
                    img_path = arr.join(".")
                }
                if (img_path.indexOf("http://") == -1 && img_path.indexOf("https://") == -1) {
                    path = Config.imgDomain + "/" + img_path
                } else {
                    path = img_path
                }
            }
            return path
        }
    },
    /**
     * 时间戳转日期格式
     * @param {Object} timeStamp
     */
    timeStampTurnTime(timeStamp) {
        if (timeStamp != undefined && timeStamp != "" && timeStamp > 0) {
            var date = new Date()
            date.setTime(timeStamp * 1000)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? "0" + m : m
            var d = date.getDate()
            d = d < 10 ? "0" + d : d
            var h = date.getHours()
            h = h < 10 ? "0" + h : h
            var minute = date.getMinutes()
            var second = date.getSeconds()
            minute = minute < 10 ? "0" + minute : minute
            second = second < 10 ? "0" + second : second
            return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second
        } else {
            return ""
        }
    },
    /**
     * 倒计时
     * @param {Object} seconds 秒
     */
    countDown(seconds) {
        let [day, hour, minute, second] = [0, 0, 0, 0]
        if (seconds > 0) {
            day = Math.floor(seconds / (60 * 60 * 24))
            hour = Math.floor(seconds / (60 * 60)) - day * 24
            minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60
            second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
        }
        if (day < 10) {
            // day = '0' + day
        }
        if (hour < 10) {
            // hour = '0' + hour
        }
        if (minute < 10) {
            // minute = '0' + minute
        }
        if (second < 10) {
            // second = '0' + second
        }
        return {
            d: day,
            h: hour,
            i: minute,
            s: second
        }
    },
    /**
     * 数值去重
     * @param {Array} arr 数组
     * @param {string} field 字段
     */
    unique(arr, field) {
        const res = new Map()
        return arr.filter(a => !res.has(a[field]) && res.set(a[field], 1))
    },
    /**
     * 判断值是否在数组中
     * @param {Object} elem
     * @param {Object} arr
     * @param {Object} i
     */
    inArray: function(elem, arr) {
        return arr == null ? -1 : arr.indexOf(elem)
    },
    /**
     * 获取某天日期
     * @param {Object} day
     */
    getDay: function(day) {
        var today = new Date()
        var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
        today.setTime(targetday_milliseconds)

        const doHandleMonth = function(month) {
            var m = month
            if (month.toString().length == 1) {
                m = "0" + month
            }
            return m
        }

        var tYear = today.getFullYear()
        var tMonth = today.getMonth()
        var tDate = today.getDate()
        var tWeek = today.getDay()
        var time = parseInt(today.getTime() / 1000)
        tMonth = doHandleMonth(tMonth + 1)
        tDate = doHandleMonth(tDate)

        const week = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
        return {
            t: time,
            y: tYear,
            m: tMonth,
            d: tDate,
            w: week[tWeek]
        }
    },
    /**
     * 复制
     * @param {Object} message
     * @param {Object} callback
     */
    copy(value, callback) {
        var oInput = document.createElement("input") //创建一个隐藏input（重要！）
        oInput.value = value //赋值
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand("Copy") // 执行浏览器复制命令
        oInput.className = "oInput"
        oInput.style.display = "none"
        vue.$message({
            message: "复制成功",
            type: "success"
        })
        typeof callback == "function" && callback()
    },
    /**
     * 深度拷贝对象
     * @param {Object} obj
     */
    deepClone(obj) {
        const isObject = function(obj) {
            return typeof obj == "object"
        }

        if (!isObject(obj)) {
            throw new Error("obj 不是一个对象！")
        }
        //判断传进来的是对象还是数组
        let isArray = Array.isArray(obj)
        let cloneObj = isArray ? [] : {}
        //通过for...in来拷贝
        for (let key in obj) {
            cloneObj[key] = isObject(obj[key]) ? this.deepClone(obj[key]) : obj[key]
        }
        return cloneObj
    },
    //验证手机号码
    checkPhone(num) {
        // 表示以1开头，第二位可能是3/4/5/7/8等的任意一个，在加上后面的\d表示数字[0-9]的9位，总共加起来11位结束。
        if (!(/^1[3|4|5|6|7|8|9]\d{9}$/.test(num))) {
            return false;
        } else {
            return true;
        }
    }
}
