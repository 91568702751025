<template>
  <el-container>
    <!-- 头部 -->
    <el-header height="auto" class="header">
      <NsHeaderTop />
    </el-header>
    <el-main class="content">
      <transition name="slide">
        <router-view />
      </transition>
      <!-- 右侧栏 -->
      <ns-aside />
    </el-main>
    <!-- 底部 -->
    <el-footer>
      <newFooter />
    </el-footer>
  </el-container>
</template>
<script>
  import NsHeaderTop from "./components/NsHeaderTop.vue"
  import NsAside from "./components/NsAside"
  import newFooter from "./components/newFooter"
  export default {
    components: {
      NsHeaderTop,
      NsAside,
      newFooter
    },
    created() {},
    data: () => {
      return {}
    },
    mounted() {},
    computed: {},
    watch: {},
  }
</script>
<style lang="scss" scoped>
  .header {
    padding: 0;
  }

  .content {
    // max-width: $width;
    width: 100% !important;
    // margin: 0 auto;
    margin: 0;
    padding: 0;
  }

  .el-footer {
    padding: 0;
    height: auto !important;
    background-color: #fff;
  }

  .el-main {
    border-top: none;
  }
</style>