<template>
    <div id="app">
<!--        <Customer></Customer>-->
        <transition name="slide">
            <router-view v-if="RouterState" />
        </transition>
        <input type="hidden" name="customServiceUrl" id="customServiceUrl" :value="customServiceUrl"></input>
    </div>
</template>
<script>
    import Customer from '@/layout/customer'
    import {memberDetail} from "@/api/member/member"
    import {getToken} from "@/utils/auth";
    import {getCustomServiceUrl} from "@/api/pc";

    export default {
        name: "APP",
        provide(){
          return {
              reload:this.reload
          }
        },
        data(){
          return{
              RouterState:true,
              customServiceUrl:''
          }
        },
        components:{
            Customer
        },
        mounted() {
            this.getCustomService()
            memberDetail({ token: getToken() }).then(res =>{
              localStorage.setItem('pc_member', JSON.stringify(res.data));
            })
        },
        methods: {
          reload() {
            this.RouterState = false;
            this.$nextTick(() => {
              this.RouterState = true;
            })
          },
          getCustomService() {
            getCustomServiceUrl({token: getToken()}).then(res => {
              this.customServiceUrl = res.data.url
            })
          }
        }
    };
</script>

<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import url("../public/main.scss");
    @import "assets/styles/main.scss";    
</style>
