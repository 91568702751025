export default {
	
	// api请求地址
	baseUrl: process.env.VUE_APP_DOMAIN,
	mealplanPid: process.env.VUE_APP_MEALPLAN_PID,
	localLacma: process.env.VUE_APP_LOCAL_LACMA,
	webSocket: process.env.VUE_APP_WSS,


	// baseUrl: 'https://mall.coc.red',
	// mealplanPid: 'https://mall.coc.red',
	// localLacma: 'https://mall.coc.red',
	// webSocket:  'wss://mall.coc.red/wss',
  
	// 图片域名
	imgDomain: '',
	
	// 腾讯地图key
	mpKey: '',
	
	// api安全
	apiSecurity: "",
	
	// 公钥
	publicKey: ``
}