import BasicLayout from "../../layout/basic"

const promotionRoutes = [
	//******************组合套餐模块（2）******************
	{
		path: "/promotion/combo-*",
		name: "combo",
		meta: {
			module: "combo"
		},
		component: () => import("@/views/promotion/combo/detail")
	},
	{
		path: "/promotion/combo_payment",
		name: "combo_payment",
		meta: {
			module: "combo",
			auth: true
		},
		component: () => import("@/views/promotion/combo/payment")
	},

	//******************秒杀模块（3）******************
	{
		path: "/promotion/seckill-*",
		name: "seckill_detail",
		meta: {
			module: "seckill",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/seckill/detail")
	},
	{
		path: "/promotion/seckill",
		name: "seckill",
		meta: {
			module: "seckill",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/seckill/list")
	},
	{
		path: "/promotion/seckill_payment",
		name: "seckill_payment",
		meta: {
			module: "seckill",
			auth: true
		},
		component: () => import("@/views/promotion/seckill/payment")
	},

	//******************团购模块（3）******************
	{
		path: "/promotion/groupbuy-*",
		name: "groupbuy_detail",
		meta: {
			module: "groupbuy",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/groupbuy/detail")
	},
	{
		path: "/promotion/groupbuy",
		name: "groupbuy",
		meta: {
			module: "groupbuy",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/groupbuy/list")
	},
	{
		path: "/promotion/groupbuy_payment",
		name: "groupbuy_payment",
		meta: {
			module: "groupbuy",
			auth: true
		},
		component: () => import("@/views/promotion/groupbuy/payment")
	},

	//******************专题活动模块（4）******************
	{
		path: "/promotion/topic-*",
		name: "topic_detail",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/detail")
	},
	{
		path: "/promotion/topic",
		name: "topic",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/list")
	},
	{
		path: "/promotion/topic-goods-*",
		name: "topic_goods_detail",
		meta: {
			module: "topic",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/topic/goods_detail")
	},
	{
		path: "/promotion/topic_payment",
		name: "topic_payment",
		meta: {
			module: "topic",
			auth: true
		},
		component: () => import("@/views/promotion/topic/payment")
	},

	//******************湘赣红专区（5）******************
	{
		path: "/promotion/redarea",
		name: "redarea",
		meta: {
			module: "redarea"
		},
		component: () => import("@/views/promotion/redarea/index")
	},
	{
		path: "/promotion/redarea/hunan",
		name: "hunan",
		meta: {
			module: "redarea"
		},
		component: () => import("@/views/promotion/redarea/hunan/index")
	},
	{
		path: "/promotion/redarea/jiangxi",
		name: "jiangxi",
		meta: {
			module: "redarea"
		},
		component: () => import("@/views/promotion/redarea/jiangxi/index")
	},

	//******************重点帮扶（5）******************
	{
		path: "/promotion/special",
		name: "special",
		meta: {
			module: "special"
		},
		component: () => import("@/views/promotion/special/index")
	},

	//******************驻村帮扶（5）******************
	{
		path: "/promotion/residencyhelp",
		name: "residencyhelp",
		meta: {
			module: "residencyhelp"
		},
		component: () => import("@/views/promotion/residencyhelp/index")
	},

	//******************东西部协作（5）******************
	{
		path: "/promotion/eastwest",
		name: "eastwest",
		meta: {
			module: "eastwest"
		},
		component: () => import("@/views/promotion/eastwest/index")
	},

	//******************优农中心（5）******************
	{
		path: "/promotion/optimalfarmers",
		name: "optimalfarmers",
		meta: {
			module: "optimalfarmers"
		},
		component: () => import("@/views/promotion/optimalfarmers/index")
	},

	//******************地方馆（5）******************
	{
		path: "/promotion/localtube",
		name: "localtube",
		meta: {
			module: "localtube"
		},
		component: () => import("@/views/promotion/localtube/index")
	},
	{
		path: "/promotion/mealplan",
		name: "mealplan",
		meta: {
			module: "mealplan",
			title: '套餐馆',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/promotion/mealplan/index")
	},
	{
		path: "/promotion/pcdiy",
		name: "pcdiy",
		meta: {
			module: "pcdiy",
			title: '微页面',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/promotion/pcdiy/index")
	},

	//******************项目相关（6）******************
	{
		path: "/promotion/projectList",
		name: "projectList",
		meta: {
			module: "projectList"
		},
		component: () => import("@/views/promotion/projectList/index")
	},
	{
		path: "/promotion/projectList/detail-*",
		name: "projectList_detail",
		meta: {
			module: "projectList"
		},
		component: () => import("@/views/promotion/projectList/detail")
	},

	//******************新闻资讯（5）******************
	{
		path: "/promotion/newsInformation",
		name: "newsInformation",
		meta: {
			module: "newsInformation"
		},
		component: () => import("@/views/promotion/newsInformation/index")
	},
	{
		path: "promotion/newsInformation/detail-*",
		name: "newsInformation_detail",
		meta: {
			module: "newsInformation",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/newsInformation/detail")
	},
]

const cmsRoutes = [{
		path: "/cms/notice",
		name: "notice",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/list")
	},
	{
		path: "/cms/notice-*",
		name: "notice_detail",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/detail")
	},
	{
		path: "/cms/help",
		name: "help",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/list"),
		children: [{
			path: "/cms/help/listother-*",
			name: "list_other",
			meta: {
				module: "help",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/help/listother")
		}]
	},
	{
		path: "/cms/help-*",
		name: "help_detail",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/detail")
	},
	{
		path: "/cms/article",
		name: "article",
		meta: {
			module: "article",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/article/list"),
		children: [{
			path: "/cms/article/listother-*",
			name: "list_other",
			meta: {
				module: "article",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/article/listother")
		}]
	},
	{
		path: "/cms/article-*",
		name: "article_detail",
		meta: {
			module: "article",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/article/detail")
	}
]

const goodsRoutes = [
	//******************商品模块（6）******************
	{
		path: "/cart",
		name: "cart",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/cart")
	},
	{
		path: "/category",
		name: "category",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/category")
	},
	{
		path: "/coupon",
		name: "coupon",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/coupon")
	},
	{
		path: "/sku-*",
		name: "detail",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/detail")
	},
	{
		path: "/list",
		name: "list",
		meta: {
			module: "goods",
			title: '商品列表',
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	},
	{
		path: "/brand",
		name: "brand",
		meta: {
			module: "goods",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/goods/brand")
	},
	{
		path: "/brandlist",
		name: "brandlist",
		meta: {
			module: "goods",
			title: '品牌专区商品列表',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/goods/brand/index")
	},
	{
		path: "/street",
		name: "street",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '店铺街'
		},
		component: () => import("@/views/shop/street")
	},
	{
		path: "/streetlst",
		name: "streetlst",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '店铺街'
		},
		component: () => import("@/views/shop/streetlst")
	},
	{
		path: "/support",
		name: "support",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '帮扶单位'
		},
		component: () => import("@/views/support/street")
	},
	{
		path: "/region",
		name: "region",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '帮扶单位'
		},
		component: () => import("@/views/region/street")
	},

]

// 其他模块
export default {
	path: "/",
	component: BasicLayout,
	redirect: "/index",
	name: "Index",
	children: [{
			path: "/index*",
			name: "index",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/index/index")
		},
		{
			path: "/change_city",
			name: "change_city",
			meta: {
				module: "index"
			},
			component: () => import("@/views/index/change_city")
		},

		...goodsRoutes,
		...cmsRoutes,
		...promotionRoutes
	]
}
