<template>
    <div class="citySit-list">
        <div class="module-list-head" >
            <h2><span></span>地方分馆</h2>
        </div>
        <div class="module-list-body">
            <el-tabs class="website_tab" v-model="activeCityName" type="card" @tab-click="handleClickWebsite">
                <el-tab-pane
                        v-for="(item,index) in websiteData"
                        :key="item.site_id"
                        :label="item.title"
                        :name="item.site_id.toString()"
                >
                    <div class="card-carousel-wrapper" v-if="recommendGoodsData.length>0">
                        <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
                        <div class="card-carousel">
                            <div class="card-carousel--overflow-container">
                                <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
                                    <div class="card-carousel--card" v-for="item in recommendGoodsData"
                                         :key="item.goods_id"
                                         @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?website_id='+item.site_id+'&source_type=1'})">
                                        <div class="goods-wrap">
                                            <div class="img-wrap"><img alt="商品图片" :src="$img(item.goods_image[0], { size: 'mid' })" @error="imageError(index)" /></div>
                                            <h3>
<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.goods_name }}</h3>
                                            <p class="desc">{{ item.introduction }}</p>
                                            <p class="price">
                                                <span class="num">￥{{ item.price }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
                        <router-link target="_blank" :to="{ path: '/citylist', query: { website_id: item.site_id, website_type: item.website_type} }">
                            <div class="card-carousel-more">
                                <div class="card-carousel-more_inner">进入分馆</div>
                                <!--                                        <i class="iconfont iconarrow-right"></i>-->
                            </div>
                        </router-link>
                    </div>
                    <div class="card-carousel-empty" v-else>
                        <router-link target="_blank" :to="{ path: '/citylist', query: { website_id: item.site_id, website_type: item.website_type} }">
                            <p style="line-height: 315px;text-align: center">暂无推荐商品！</p>
                        </router-link>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import {recommendGoodsList, websiteList} from "@/api/goods/goods";

    export default {
        components: {},
        computed: {
            atEndOfList() {
                return this.currentOffset <= (this.paginationFactor * -1) * (this.recommendGoodsData.length - this.windowSize);
            },
            atHeadOfList() {
                return this.currentOffset === 0;
            }
        },
        data() {
            return {
                currentOffset: 0,
                windowSize: 4,
                paginationFactor: 234,
                activeCityName: '0',
                websiteData:[],
                recommendGoodsData:[],
                webSiteType: 1,
                citySitCount:0,
            };
        },
        mounted() {
            this.getWebsiteList()
        },
        methods: {
            moveCarousel(direction) {
                if (direction === 1 && !this.atEndOfList) {
                    this.currentOffset -= this.paginationFactor;
                } else if (direction === -1 && !this.atHeadOfList) {
                    this.currentOffset += this.paginationFactor;
                }
            },
            handleClickWebsite(tab, event){
                // console.log('点击==',tab.name);
                this.currentOffset =0
                let site_id = (typeof tab.name !='undefined' ) ? parseInt(tab.name) : 0
                this.getRecommendGoodsList(site_id,1)
            },
            //获取城市分站集合
            getWebsiteList() {
                websiteList({ type: 1 })
                    .then(res => {
                        if (res.code == 0) {
                            this.websiteData = res.data
                            let site_id = (typeof res.data[0] !='undefined' ) ? res.data[0].site_id : 0
                            let website_type = (typeof res.data[0] !='undefined' ) ? res.data[0].website_type : 0
                            if(site_id > 0 && website_type > 0){
                                this.activeCityName = (site_id).toString()
                                this.getRecommendGoodsList(site_id,website_type)
                            }
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
            //获取城市分站推荐产品
            getRecommendGoodsList(site_id,website_type) {
                recommendGoodsList({ site_id: site_id, website_type: website_type })
                    .then(res => {
                        if (res.code == 0 && res.data.list.length>0) {
                            // console.log('获取城市分站推荐产品==', res.data)
                            this.recommendGoodsData = res.data.list;
                        }else{
                            this.recommendGoodsData =[]
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
        }
    }
</script>

<style lang="scss">
    /*城市分站*/
    .website_tab .el-tabs__nav-wrap{margin-bottom:0;}
    .website_tab.el-tabs--card > .el-tabs__header{
        margin-bottom:-1px;
        border:none;
    }
    .website_tab.el-tabs--card > .el-tabs__header .el-tabs__nav{
        border:none
    }
    .website_tab.el-tabs--card > .el-tabs__header .el-tabs__item{
        background-color: #fff;
        font-size: 15px;
        color:#837777;
        border-left: none;
        border-bottom: 1px solid $base-color;
    }
    .website_tab.el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
        font-size: 16px;
        font-weight: 700;
        color:#301D1D;
        background-color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border:1px solid $base-color;
        border-bottom:none;
        bottom:-5px;
        z-index: 1000;
        padding: 0px 35px;
    }
    .website_tab.el-tabs--card > .el-tabs__content{
        border:1px solid $base-color;
        border-radius: 8px;
        border-top-left-radius: 0px;
    }

    .citySit-list {
        width: 1210px;
        display: block;
        margin: 20px auto 0;
        padding: 20px 0px 0px;
        background-color: #fff;
        border-radius: 8px;
        border-radius: 8px;

        .module-list-head {
            padding: 0px 20px;
            margin: 0 auto 10px;
            h2 {
                line-height: 48px;
                color: #333;
                padding: 0px 0px;
                font-size: 20px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;

                span {
                    border: 3px solid $base-color;
                    border-radius: 4px;
                    height: 14px;
                    margin-right:10px;
                    display: inline-block;
                }
            }
        }

        .module-list-body {
            padding:0px 25px 25px;
            .module-list-wrap {
                width: 1170px;
                overflow: hidden;
                display: flex;
                .left-icon{
                    width: 30px;
                    float: right
                }
                .right-icon{
                    width: 30px;
                    float: right
                }

                .module-list{
                    width: 100%;
                    float:left;
                    li{
                        font-style: normal;
                        display: inline-block;
                        width: 100px;
                        list-style: none;
                    }
                }
            }
        }
    }
    .card-carousel-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0 auto;
        .card-carousel {
            justify-content: center;
            width: 1040px;
            display: flex;
            position: relative;
            overflow: hidden;
            height: 360px;
            .card-carousel-cards {
                display: flex;
                transition: transform 150ms ease-out;
                transform: translatex(0px);
                .card-carousel--card {
                    background: #fff;
                    cursor: pointer;
                    padding:0;
                    transition: all 0.2s linear;
                    z-index: 3;
                    margin: 20px 10px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    .goods-wrap{
                        width: 214px;
                        padding: 15px 0px;
                        .img-wrap {
                            width: 170px;
                            height: 170px;
                            margin: 0px auto;
                            text-align: center;
                            line-height: 170px;
                            /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                            /*border-radius: 16px;*/
                            overflow: hidden;
                            position:relative;
                            img {
                                border: none;
                                position: absolute;
                                margin-right: -75px;
                                top:50%;
                                left:50%;
                                display: block;
                                transform: translate(-50%,-50%);
                            }
                        }
                        h3 {
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 25px;
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin: 10px 15px 5px;
                        }
                        .desc {
                            margin:0 15px;
                            font-size: 12px;
                            line-height:16px;
                            color: #b0b0b0;
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .price {
                            margin:0 15px;
                            font-size: 16px;
                            text-align: left;
                            color: $base-color;
                            del {
                                margin-left: 10px;
                                color: #b0b0b0;
                                font-size: 16px;
                            }
                        }
                    }

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }
                }
                .card-carousel--card:first-child {
                    margin-left: 10px;
                }
                .card-carousel--card:last-child {
                    margin-right: 10px;
                }
            }
        }
        .card-carousel--overflow-container {
            overflow: hidden;
            padding: 10px 0px;
        }

        .card-carousel-more {
            background: #f7f7f7 url("../../../assets/images/city_more.png") no-repeat center center;
            display: inline-block;
            width: 90px;
            height: 360px;
            margin: 0;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            line-height: 360px;
            .card-carousel-more_inner {
                display: inline-block;
                width: 10px;
                line-height: 50px;
                vertical-align: middle;
                font-size: 20px;
                padding-left: 40%;
                cursor: pointer;
                color:#fff;
            }
            i{
                line-height: 315px;
                padding-left:30px;
            }

        }
        .card-carousel--nav__left, .card-carousel--nav__right {
            display: inline-block;
            width: 15px;
            height: 15px;
            padding: 0;
            box-sizing: border-box;
            border-top: 2px solid $base-color;
            border-right: 2px solid $base-color;
            cursor: pointer;
            margin: 0 10px;
            transition: transform 150ms linear;
        }
        .card-carousel--nav__left[disabled], .card-carousel--nav__right[disabled] {
            opacity: 0.2;
            border-color: black;
        }
        .card-carousel--nav__left {
            transform: rotate(-135deg);
        }
        .card-carousel--nav__left:active {
            transform: rotate(-135deg) scale(0.9);
        }
        .card-carousel--nav__right {
            transform: rotate(45deg);
        }
        .card-carousel--nav__right:active {
            transform: rotate(45deg) scale(0.9);
        }
    }
    /*城市分站*/
</style>